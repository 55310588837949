import { StyleSheet, Text, View } from 'react-native'
import React, { useState, useEffect } from 'react'

const LeaderboardOptions = () => {
  return (
    <View>
      <Text>LeaderboardOptions</Text>
    </View>
  )
}

export default LeaderboardOptions

const styles = StyleSheet.create({})
